import { nodeListToArray } from '../helpers/nodeListToArray';

export function imageOrientation(imageBlock:HTMLElement, cssClass?:string, waitForLoad?: boolean) {
    let image = imageBlock.querySelector('img');
    let imageLoad = (waitForLoad) ? waitForLoad : true;
    if (cssClass === undefined || cssClass === '') {
        cssClass = 'portrait';
    }
    if (waitForLoad) {
        image.onload = () => {
            if (image.naturalWidth < image.naturalHeight) {
                imageBlock.classList.add(cssClass);
            }
        }
    }
    if (!waitForLoad) {
        if (image.naturalWidth < image.naturalHeight) {
            imageBlock.classList.add(cssClass);
        }
    }
       
}