/**
    This file holds all the constants used by native js(typescript) code
*/
import { nodeListToArray } from '../helpers/nodeListToArray';
export const GLOBAL_BODY: any = document.querySelector('body');
export const GLOBAL_HTML: any = document.getElementsByTagName('html')[0];
export const GLOBAL_NAV: any = document.getElementById('navContainer');
export const GLOBAL_HEADER: any = document.getElementById('header');
export const GLOBAL_FOOTER: any = document.getElementById('footer');
export const GLOBAL_SITE_WRAPPER: any = document.getElementById('siteWrapper');
export const GLOBAL_UNSUPPORTED_MODAL: any = document.getElementById('unsupportedModal');
export const GLOBAL_DURATION = 750;//milliseconds
export const GLOBAL_MAIN_CONTENT = document.getElementById('main');
export const GLOBAL_TABLET_BREAKPOINT = 1025;
export const GLOBAL_SUBNAV: any = document.getElementById('subNav');
export const GLOBAL_PDP_STICKY_CTA: any = document.querySelector('[data-pdp-sticky]');
export const EPI_EDIT_MODE_CLASS: string = "epi-block-preview";
export const SCROLL_TO_ATTR: string = "data-block-animate";
export const GLOBAL_WRAPPER: HTMLElement = document.getElementById("lyricContainer");
export const GLOBAL_SKIPLINK: HTMLElement = document.getElementById("skipLink");
export const NAV_OPEN_CLASS = 'nav-open';
export const GLOBAL_NAV_PANEL: HTMLElement = document.getElementById("lyricNavPanel");
export const GLOBAL_NAV_TRIGGER: HTMLElement = document.getElementById("lyricNavTrigger");
export const GLOBAL_SEARCH_PANEL: HTMLElement = document.getElementById("utilitySearch");
export const IS_HOMEPAGE = document.querySelector('[data-homepage]') != null;
export const NAV_IS_ON_LEFT = function () {
    return window.innerWidth > 1024;
};
export const GET_HEADER_HEIGHT = function () {
    return !NAV_IS_ON_LEFT() ? GLOBAL_HEADER.getBoundingClientRect().height : 0;
}
export const DATA_NAV_OPEN_ATTR: string = 'data-nav-open';
export const DATA_SEARCH_OPEN_ATTR: string = 'data-search-open';