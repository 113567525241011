import * as CONSTANTS from '../modules/constants';
import { nodeListToArray } from '../helpers/nodeListToArray';
export class ButtonRole {
    public buttons = nodeListToArray(document.querySelectorAll('[role=button]'));
	constructor() {
        if (this.buttons.length > 1) {
            this.buttons.forEach(button => {
                button.addEventListener("click", (e) => {
                    this.buttonClick(e);
                });
                button.addEventListener("keyup", (e) => {
                    this.buttonKey(e);
                });
            });
        }
    }

    buttonClick(e: MouseEvent) {
        e.preventDefault();
    }

    buttonKey(e: KeyboardEvent) {
        if (e.keyCode === 32) {
            e.preventDefault();
        }
    }

}