export class ScrollPrompt {
    private scrollPrompt: HTMLElement;
    constructor() {
        this.scrollPrompt = (document.querySelector('[data-frame-prompt]') as HTMLElement);
        this.activateScrollPrompt();
    }
    activateScrollPrompt() {
        if (this.scrollPrompt) {
            (window as any).addEventListener('scroll', (e) => {
                if (!this.scrollPrompt.classList.contains('frame-prompt-scrolled'))
                    this.scrollPrompt.classList.add('frame-prompt-scrolled');
            });
        }
        
    }

}