import { nodeListToArray } from "../helpers/nodeListToArray";

export class toggleContainer {
    container: HTMLElement;
    trigger: HTMLElement;
    panel: HTMLElement;
    links: [];

    constructor(container: HTMLElement) {
        this.container = container as HTMLElement;
        this.trigger = container.querySelector('[data-toggle-trigger]') as HTMLElement;
        this.panel = container.querySelector('[data-toggle-panel]') as HTMLElement;
        this.links = nodeListToArray(container.querySelectorAll('[data-toggle-link]'));
        this.toggleContainer();
        this.linkListeners();
    }

    toggleContainer() {
        this.trigger.addEventListener('click', () => {
            this.container.classList.toggle('show');
            if (this.panel.getAttribute('aria-expanded') === 'true') {
                this.panel.setAttribute('aria-expanded', 'false');
            }
            else {
                this.panel.setAttribute('aria-expanded', 'true');
            }
        })
    }

    linkListeners() {
        if (this.links.length > 0) {
            this.links.forEach((link: HTMLElement) => {
                link.addEventListener('click', () => {
                    this.panel.setAttribute('aria-expanded', 'false');
                })
            })
        }
    }
}
