import { nodeListToArray } from '../helpers/nodeListToArray';
import * as CONSTANTS from '../modules/constants';
import * as Cookie from '../helpers/cookie';
import { debounce } from '../helpers/debounce'; 
export class Alert {
    private alerts = nodeListToArray(document.querySelectorAll('[data-alert]'));
    private cookie = '';
    private body = (CONSTANTS.GLOBAL_BODY as HTMLElement);
    private header = document.getElementById('header');
    constructor() {
        if (this.alerts.length > 0) {
            this.alerts.forEach(alert => {
                const alertCookieName = alert.getAttribute('data-alert-cookie');
                let isDismissed = (Cookie.getCookie(alertCookieName) == null) ? false : true;
                const alertCloseButton = alert.querySelector('[data-alert-close]');
                alertCloseButton.addEventListener('click', () => {
                    this.closeAlert(alertCloseButton); 
                });
                
                if (!isDismissed) {
                    this.showAlert(alert);
                }
            });
        }
        this.checkHeaderAlerts();
        this.stickyAlert();
        this.updateHTMLElementSizesToCompensateForAlerts();
        window.addEventListener('resize', () => {
            debounce(this.updateHTMLElementSizesToCompensateForAlerts(), 100);
        });
    }
    updateHTMLElementSizesToCompensateForAlerts() {
        let bodyPaddingAdjustmentAmount = 0;
        const shownAlert = document.querySelector('[data-alert-id=global-alert][aria-hidden=false]');
       
        //Do not dynamically calculate if it's desktop
        if (CONSTANTS.NAV_IS_ON_LEFT()) {
            this.removeBodyStyles();
        }
    }

    showAlert(alertToShow: HTMLElement) {
        alertToShow.setAttribute('aria-hidden', 'false');
        alertToShow.classList.remove('hide');
    }

    hideAlert(alertToClose: HTMLElement) {
        alertToClose.setAttribute('aria-hidden', 'true');
        alertToClose.classList.add('hide');
    }

    closeAlert(alertCloseButton) {
        const alertToClose = document.getElementById(alertCloseButton.getAttribute('data-alert-close'));
        const alertCookieName = alertToClose.getAttribute('data-alert-cookie');
        this.cookie = Cookie.getCookie(alertCookieName);
        if (!this.cookie) {
            this.hideAlert(alertToClose);
            Cookie.setCookie(alertCookieName, true, 1);
        }
        if (alertCookieName === 'alertsdismissed') {
            this.removeBodyStyles();
        }
        this.checkHeaderAlerts();
        this.setNavClearance();
    }

    removeBodyStyles() {
        this.body.removeAttribute('style');
    }

    checkHeaderAlerts() {
        if (document.querySelector('[data-alert-id=global-alert].hide')) {
            document.body.classList.remove('has-lyric-alert');
        }
    }

    stickyAlert() {
        let scrollTopElement = document.getElementById('footer-alert');
        let showStickyClass = 'sticky';
        let scrollElementHeight = document.getElementById('footer').clientHeight;
        if (scrollTopElement) {
            window.addEventListener('scroll', () => {
                window.requestAnimationFrame(() => {
                    if ((window.innerHeight + window.pageYOffset) >= (document.body.scrollHeight - scrollElementHeight)) {

                        scrollTopElement.classList.add(showStickyClass);

                    } else {
                        scrollTopElement.classList.remove(showStickyClass);
                    }
                });
            });
        }
    }

    setNavClearance() {
        document.documentElement.style.setProperty('--nav-clearance', `${this.header.offsetHeight.toString()}px`);
    }
}
