export function initPhoneFormatter () {
    const attributeName = "auto-format";
    const autoFormatInputs = document.querySelectorAll(`input[${attributeName}]`);
    // Pipes in the template indicate clean breakpoints to trim to when digits beyond them aren't available
    // e.g, 112          -> '(112) '            leaving a nice space
    // e.g, 223-121-1233 -> '(223) 121-1233'    trimming off the 'ext ' part
    const defaultTemplate = "(ddd) |ddd-|dddd| ext dddd";
    const attachAutoFormatter = (inputEl) => inputEl.addEventListener('blur', () => {
        const customTemplate = inputEl.getAttribute(attributeName);
        inputEl.value = mapInputToTemplate(inputEl.value, customTemplate ? customTemplate : defaultTemplate);
        // triggers webforms validation
        inputEl.dispatchEvent(new Event('change'));
    }, true);
    autoFormatInputs.forEach(attachAutoFormatter);
}

const getMatchArr = (str, regex) => str.split('')
    .map((char, i) => regex.test(char) ? i : -1)
    .filter(value => value !== -1)

const pipeTrim = (charArr) => {
    let exposedPipe = -1;
    for (let i = charArr.length - 1; i >= 0; i--) {
        if (/\d/.test(charArr[i]))
            break;
        if (charArr[i] == '|') {
            exposedPipe = i;
            break;
        }
    }

    return (exposedPipe > -1) ? charArr.slice(0, exposedPipe) : charArr;
}

const mapInputToTemplate = (input, template) => {
    if (input.length == 0 || !/\d/.test(input))
        return "";

    const inputMatchArr = getMatchArr(input, /\d/);
    const templateMatchArr = getMatchArr(template, /d/);

    const inputArr = input.split('');
    const resultArr = template.split('');
    templateMatchArr.forEach((matchIndex, arrIndex) =>
        resultArr[matchIndex] = inputArr[inputMatchArr[arrIndex]]);

    let result = resultArr;
    if (resultArr.includes(undefined)) {
        const firstUndefined = resultArr.indexOf(undefined);
        const trimmedResult = resultArr.slice(0, firstUndefined);
        result = pipeTrim(trimmedResult);
    }

    return result.filter(c => c !== '|').join('');
}

