import Swiper from 'swiper';
import { assign } from 'lodash';
import { nodeListToArray } from '../helpers/nodeListToArray';
import * as YouTubeIframeLoader from 'youtube-iframe';
import Player from '@vimeo/player';
import { imageOrientation } from '../modules/image-orientation';
import { debounce } from 'lodash';

export interface IGallery {
    containerClass: string;
    slidesPerView?: number | string;
    breakpoints?: any;
    spaceBetween?: number;
    navigation?: any;
    autoHeight?: boolean;
    loop?: boolean;
    autoplayVideo?: boolean;
    pageNumberPreposition?: string;
    updateOnImagesReady?: boolean;
    imagesReady?: Function;
    allowVideo?: boolean;
    calculateMinimumSlides?: boolean;

}

export class Gallery {
    //these params are exposed to the file you intialize your gallery in, so you can initialize multiple
    //gallery versions across your project or just be able to configure without diving into this file
    params: IGallery = {
        containerClass: 'adage-gallery-js',
        slidesPerView: 2,
        breakpoints: {
            640: {
                slidesPerView: 1,
                spaceBetween: 0
            }
        },
        spaceBetween: 0,
        navigation: {
            nextEl: '.swiper-button-next',
            prevEl: '.swiper-button-prev'
        },
        autoHeight: false,
        loop: true,
        autoplayVideo: false,
        pageNumberPreposition: ' / ',
        updateOnImagesReady: true,
        imagesReady: (gallery) => {
            if (gallery) {
                this.applyPortraitClass(this.gallery.slides, 'portrait');
            }
        },
        allowVideo: true,

    };
    gallery: any;
    activeSlide: any;
    previousSlide: any;
    activeIndex: any;
    previousIndex: number; // swiper's previousIndex implementation doesn't work with loop: true so we need to do it manually
    videos: any;
    galleryId: string;
    responsiveInit: boolean;
    galleryContainer: HTMLElement;
    videoElement: any;
    staticCssClass: string;
    video?: any;


    constructor(params?: IGallery) {
        assign(this.params, params);
        this.staticCssClass = 'adage-gallery-static';
        this.responsiveInit = false;
        this.galleryContainer = document.getElementById(`contentGallery_${document.querySelector(`.${this.params.containerClass}`).getAttribute('data-unique-id')}`);
        if (this.galleryHasMinimumAmountOfSlides()) {
            this.initializeSwiper();
            this.galleryId = (this.gallery) ? this.gallery.el.getAttribute('data-unique-id') : document.querySelector(`.${this.params.containerClass}`);
            this.videos = {};
            this.initCallback();
        }
        else {
            this.galleryContainer.classList.add(this.staticCssClass);
            this.applyPortraitClass(nodeListToArray(document.querySelectorAll('.adage-gallery-item')), 'portrait');
            if (this.params.allowVideo) {
                this.videoElement = nodeListToArray(this.galleryContainer.querySelectorAll('.adage-gallery-static .adage-gallery-container'));
                this.videoElement.forEach(el => {
                    this.initVisibleVideos(el);
                })
            }
        }
        if ((document.querySelector(`.${this.params.containerClass}`).getAttribute('data-total-slides') == "0")) {
            if (this.galleryContainer.classList.contains('adage-bordered-block')) {
                (this.galleryContainer.classList.remove('adage-bordered-block'))
            };
        }

    }

    applyPortraitClass(slides: any, cssClass: string) {
        nodeListToArray(slides).forEach(slide => {
            if (slide.querySelector('img')) {
                imageOrientation(slide, cssClass, false);
            }
        });
    }

    isMobile() {
        return window.matchMedia('(min-width: 0px) and (max-width: 640px)').matches;
    }

    initializeSwiper() {
        if (!this.responsiveInit) {
            this.responsiveInit = true;
            this.gallery = new Swiper(`.${this.params.containerClass}`, {
                // feel free to add any other Swiper settings if needed
                slidesPerView: this.params.slidesPerView,
                breakpoints: this.params.breakpoints,
                spaceBetween: this.params.spaceBetween,
                navigation: this.params.navigation,
                autoHeight: this.params.autoHeight,
                updateOnImagesReady: this.params.updateOnImagesReady,
                loop: this.params.loop,
                loopAdditionalSlides: (typeof this.params.slidesPerView === 'number') ? this.params.slidesPerView + 1 : false,
                on: {
                    slideChangeTransitionStart: this.handleSlide.bind(this),
                    imagesReady: () => {
                        setTimeout(() => {
                            this.params.imagesReady(this.gallery);
                            this.gallery.el.classList.add('adage-gallery-container-show');
                        }, 1);
                    },
                    init: () => {
                        setTimeout(() => {
                            this.determineNavVisibility();
                        }, 1);
                    },
                    breakpoint: () => {
                        setTimeout(() => {
                            this.determineNavVisibility();
                        }, 1);
                    }
                },
                a11y: true
            });
        }
    }

    determineNavVisibility() {
        if (this.gallery.slides) {
            if (this.gallery.slides.length <= this.gallery.params.slidesPerView) {
                this.galleryContainer.classList.add(this.staticCssClass);
            }
            else {
                this.galleryContainer.classList.remove(this.staticCssClass);
            }
        }

    }

    galleryHasMinimumAmountOfSlides() {
        return parseInt(document.querySelector(`.${this.params.containerClass}`).getAttribute('data-total-slides')) > 1;
    }

    initCallback() {
        if (this.gallery && this.galleryHasMinimumAmountOfSlides()) {
            if (this.gallery.el.hasAttribute('data-gallery-disable-mobile')) {
                window.addEventListener(
                    'resize',
                    debounce(() => {
                        if (this.isMobile()) {
                            this.gallery.destroy();
                            this.galleryContainer.classList.add(this.staticCssClass);
                            this.responsiveInit = false;
                        }
                        else {
                            this.initializeSwiper();
                        }
                    }, 100)
                );
            }
            if (this.isMobile() && this.gallery.el.hasAttribute('data-gallery-disable-mobile')) {
                this.gallery.destroy();
                this.galleryContainer.classList.add(this.staticCssClass);
                this.responsiveInit = false;
            }
            else {
                this.activeSlide = this.gallery.slides[this.gallery.activeIndex];
                this.activeIndex = this.gallery.activeIndex;
                this.setPageNumbers();
                if (this.params.allowVideo)
                    this.initVisibleVideos(this.videoElement);
            }
        }
    }

    setPageNumbers() {
        if (this.gallery && this.gallery.el.getAttribute('data-total-slides')) {
            let totalSlides = this.gallery.el.getAttribute('data-total-slides');
            for (let i = 0; i < this.gallery.slides.length; i++) {
                let slide = this.gallery.slides[i];
                this.reconcileDupeId(slide);
                if (i !== this.activeIndex) {
                    this.disableSlide(slide);
                }
                let slidePageContainer = slide.querySelector('[data-gallery-page]');
                if (slidePageContainer) {
                    let pageString = `${slide.getAttribute('data-slide-index')}${this.params.pageNumberPreposition}${totalSlides}`;
                    slidePageContainer.innerHTML = pageString;
                }
            }
        }
    }

    reconcileDupeId(slide) {
        // check to see if there's 2 slides with same unique ID (when loop is true, slides get duplicated)
        if (slide.querySelector('[data-unique-id]')) {
            let thisId = slide.querySelector('[data-unique-id]').getAttribute('data-unique-id');
            if (this.gallery.el.querySelectorAll(`[data-unique-id="${thisId}"]`).length > 1) {
                let dupes = Array.prototype.slice.call(this.gallery.el.querySelectorAll(`[data-unique-id="${thisId}"]`));
                dupes.forEach((dupe, index) => {
                    dupe.setAttribute('data-unique-id', `${thisId}-${index}`);
                })
            }
        }
    }

    handleSlide() {
        if (this.gallery) {
            this.previousSlide = this.activeSlide;
            this.previousIndex = this.activeIndex;
            this.activeSlide = this.gallery.slides[this.gallery.activeIndex];
            this.activeIndex = this.gallery.activeIndex;
            if (this.params.allowVideo)
                this.initVisibleVideos(null);
            this.pauseInactiveVideos();
            this.disableSlide(this.previousSlide);
            this.enableSlide(this.activeSlide);
        }
    }

    initVisibleVideos(videoElement) {

        if (this.activeSlide) {
            let activeSlideVideo = this.activeSlide.querySelector('[data-video-type]');
            if (activeSlideVideo) {
                let videoType = activeSlideVideo.getAttribute('data-video-type');
                if (videoType == 'vimeo') {
                    this.vimeoInit(videoElement);
                }
                else if (videoType == 'youtube') {
                    this.youtubeInit(videoElement);
                }
                else {
                    this.nativeVideoInit(videoElement);
                }
            }
        }
        else if (videoElement) {
            let video = videoElement.querySelector('[data-video-type]');
            if (video) {
                let videoType = video.getAttribute('data-video-type');
                if (videoType == 'vimeo') {
                    this.vimeoInit(videoElement);
                }
                else if (videoType == 'youtube') {
                    this.youtubeInit(videoElement);
                }
                else {
                    this.nativeVideoInit(videoElement);
                }
            }
        }
    }

    pauseInactiveVideos() {
        let prevSlideVideo = this.previousSlide.querySelector('[data-video-type]');
        if (prevSlideVideo) {
            this.pauseVid(this.videoElement);
        }
    }

    vimeoInit(videoElement) {
        if (this.activeSlide) {
            let vimeoIframe = this.activeSlide.querySelector('iframe');
            if (!vimeoIframe.src) {
                vimeoIframe.src = vimeoIframe.getAttribute('data-src');
                let vimeoPlayer = new Player(vimeoIframe, {});
                vimeoPlayer.on('loaded', this.playVid.bind(this));
                this.videos[this.gallery.activeIndex] = vimeoPlayer;
            }
            else {
                this.playVid();
            }
        }
        else if (videoElement) {
            let vimeoIframe = this.videoElement.querySelector('iframe');
            if (!vimeoIframe.src) {
                vimeoIframe.src = vimeoIframe.getAttribute('data-src');
                let vimeoPlayer = new Player(vimeoIframe, {});
                this.video = vimeoPlayer;
            }
        }
    }


    youtubeInit(videoElement) {
        if (this.activeSlide) {
            const youtubeIframe = this.activeSlide.querySelector('iframe');

            if (!youtubeIframe.closest('[data-standalone-video]')) {
                if (!youtubeIframe.src) {
                    // Only use load init if the YT API hasn't been initialized yet
                    if ((window as any).YT == undefined || (window as any).YT.loaded != 1) {
                        YouTubeIframeLoader.load(YT => {
                            let playerID = `youtube-player-${this.galleryId}-${this.gallery.activeIndex}`;
                            youtubeIframe.id = playerID;
                            youtubeIframe.src = youtubeIframe.getAttribute('data-src');
                            let youtubePlayer = new YT.Player(playerID, {
                                events: {
                                    'onReady': this.playVid.bind(this)
                                }
                            });
                            this.videos[this.gallery.activeIndex] = youtubePlayer;
                        });
                    }
                    else {
                        let playerID = `youtube-player-${this.galleryId}-${this.gallery.activeIndex}`;
                        youtubeIframe.id = playerID;
                        youtubeIframe.src = youtubeIframe.getAttribute('data-src');
                        //Init Video
                        let youtubePlayer = new (window as any).YT.Player(playerID, {
                            events: {
                                'onReady': () => {
                                }
                            }
                        });
                        this.video = youtubePlayer;
                    }
                }
                else {
                    this.playVid();
                }
            }
        }
        else if (videoElement) {
            const youtubeIframe = videoElement.querySelector('iframe');
            if (!youtubeIframe.closest('[data-standalone-video]')) {
                if (!youtubeIframe.src) {
                    let uniqueID = this.videoElement.id;


                    YouTubeIframeLoader.load(YT => {
                        let playerID = `youtube-player-${uniqueID}`;
                        youtubeIframe.id = playerID;
                        youtubeIframe.src = youtubeIframe.getAttribute('data-src');
                        //Init Video
                        let youtubePlayer = new YT.Player(playerID, {
                            events: {
                                'onReady': () => {
                                }
                            }
                        });
                        this.video = youtubePlayer;

                    });
                }



            }
        }
    }

    nativeVideoInit(videoElement) {
        if (this.activeSlide) {
            let videoElement = this.activeSlide.querySelector('video');
            if (videoElement) {
                let videoSource = videoElement.querySelector('source');
                if (!videoSource.src) {
                    videoSource.src = videoSource.getAttribute('data-src');
                    videoElement.load();
                    videoElement.addEventListener('loadeddata', () => {
                        this.playVid.bind(this);
                    })
                    this.videos[this.gallery.activeIndex] = videoElement;
                }
            }
        }
        else if (videoElement) {
            let nativeElement = videoElement.querySelector('video');
            if (videoElement) {
                let videoSource = nativeElement.querySelector('source');
                if (!videoSource.src) {
                    videoSource.src = videoSource.getAttribute('data-src');
                    videoElement.load();
                }
                this.video = nativeElement;
            }
        }
    }

    playVid() {
        if (this.activeSlide) {
            let player = this.videos[this.gallery.activeIndex];
            let videoContainer = this.activeSlide.querySelector('[data-video-type]');
            if (this.params.autoplayVideo && this.previousSlide) {
                if (videoContainer.getAttribute('data-video-type') == 'vimeo') {
                    player.play();
                }
                else if (videoContainer.getAttribute('data-video-type') == 'youtube') {
                    player.playVideo();
                }
                else {
                    player.play();
                }
            }
        }
    }

    pauseVid(videoElement) {

        if (this.activeSlide) {
            let player = this.videos[this.previousIndex];
            let videoContainer = this.previousSlide.querySelector('[data-video-type]');
            if (videoContainer.getAttribute('data-video-type') == 'vimeo') {
                player.pause();
            }
            else if (videoContainer.getAttribute('data-video-type') == 'youtube') {
                if (player) {
                    player.pauseVideo();
                }
            }
            else {
                if (!player.paused) {
                    player.pause();
                }
            }
        }
        else if (videoElement) {
            let videoContainer = videoElement.querySelector('[data-video-type]');
            if (videoContainer) {
                if (videoContainer.getAttribute('data-video-type') == 'vimeo') {
                    this.video.pause();
                }
                else if (videoContainer.getAttribute('data-video-type') == 'youtube') {
                    this.video.pauseVideo();
                }
                else {
                    if (!this.video.paused) {
                        this.video.pause();
                    }
                }
            }
        }
    }

    disableSlide(slide) {
        // disable tabbing on tabbable content by default to stop trapping keyboard, and hide from screenreader users
        let tabbableContent = Array.prototype.slice.call(slide.querySelectorAll('a, input, button, area, object, select, iframe, video, audio'));
        tabbableContent.forEach(item => {
            item.tabIndex = -1;
        });
        slide.setAttribute('aria-hidden', 'true');
    }

    enableSlide(slide) {
        // enable tabbing on active slide and show to screenreader users
        let tabbableContent = Array.prototype.slice.call(slide.querySelectorAll('a, input, button, area, object, select, iframe, video, audio'));
        tabbableContent.forEach(item => {
            item.tabIndex = 0;
        });
        slide.setAttribute('aria-hidden', 'false');
    }


}