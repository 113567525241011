import { enableTabbableChildren } from "../helpers/enableTabbableChildren";
import { disableTabbableChildren } from "../helpers/disableTabbableChildren";

export class BasicButtonToggling {
    public expandedStatus: boolean = false;
    constructor() {
    }
    testAriaControls(element:HTMLElement) {
        if (element.getAttribute("aria-controls") === null) {
            element = (element.closest('[aria-controls]') as HTMLElement);
            if (element === null)
                return false;
        }
        else
            element = element;
        return element;
    }
    handleClick = (e: Event) => { 
        const clickedElement: HTMLElement | boolean = this.testAriaControls((e.target as HTMLElement));
        if (clickedElement) {
            const elementToToggle = document.getElementById(clickedElement.getAttribute('aria-controls'));
            this.expandedStatus = (clickedElement.getAttribute('aria-expanded') === 'false') ? true : false;
            this.updateAriaExpanded(clickedElement, this.expandedStatus.toString());
            this.updateInertAttribute(!this.expandedStatus, elementToToggle);
            if (this.expandedStatus)
                enableTabbableChildren(elementToToggle);
        }  
        e.stopPropagation();
    }
    updateInertAttribute(shouldBeInert: boolean, elementToInertify: HTMLElement) {
        if (shouldBeInert)
            this.addInert(elementToInertify);
        else
            this.removeInert(elementToInertify);
    }
    removeInert(element: HTMLElement) {
        (element as any).inert = false;
    }
    addInert(element: HTMLElement) {
        (element as any).inert = true;
    }
    updateAriaExpanded(button: HTMLElement, status: string) {
        button.setAttribute('aria-expanded', status);
    }
    updateExpandedStatus(newStatus: boolean) { 
        this.expandedStatus = newStatus;
    }
}