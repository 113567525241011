import { getIEVersion } from '../helpers/getIEVersion';
import { setTimeout } from 'timers';
 
export class FullBleedImage {
    imgTag: HTMLImageElement;
    constructor(imageEl: HTMLImageElement) {
        this.imgTag = imageEl;
        if (getIEVersion() > 0) {
            this.replaceImg();
        }
    }

    replaceImg() {
        let imgReplacement: HTMLElement;
        if (this.imgTag.dataset.fullBleedInline !== undefined) {
            imgReplacement = document.createElement('span');
        }
        else { 
            imgReplacement = document.createElement('div');
        }
        if (this.imgTag.dataset.objectPosition != "undefined" && this.imgTag.dataset.objectPosition !== null) {
            imgReplacement.style.cssText = `background-position: ${this.imgTag.dataset.objectPosition}; background-image: url('${this.imgTag.src}')`; 
        }
        else {
            imgReplacement.style.backgroundImage = `url('${this.imgTag.src}')`;
        }
        imgReplacement.setAttribute('data-full-bleed-image-ie', '');
        if (this.imgTag.alt.length > 0) {
            let altReplacement = document.createElement('p');
            altReplacement.className = 'visually-hidden';
            altReplacement.innerHTML = this.imgTag.alt;
            imgReplacement.appendChild(altReplacement);
        }
        this.imgTag.parentNode.replaceChild(imgReplacement, this.imgTag);
        setTimeout(() => {
            imgReplacement.className = this.imgTag.className;
        }, 300);
    }
}