import { throttle } from 'lodash';

export function StickyObserver(stickyElement: HTMLElement) {
    let isStuck = false;

    const checkScroll = () => {
        const navOffset = stickyElement.getBoundingClientRect().top;
        let navClearance = parseInt(getComputedStyle(document.documentElement).getPropertyValue('--nav-clearance'))
        if (navOffset <= navClearance + 25) {
            if (!isStuck) {
                isStuck = true;
                stickyElement.classList.add('stuck');
            }
        }
        else {
            if (isStuck) {
                isStuck = false;
                stickyElement.classList.remove('stuck');
            }
        }
    }

    window.addEventListener('scroll', throttle(checkScroll, 50));
    window.addEventListener('load', () => {
        checkScroll();
    })
}

