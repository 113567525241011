import * as CONSTANTS from '../constants';
import * as NAV_CONSTANTS from '../nav-constants';
import { BasicButtonToggling } from '../../modules/basic-button-toggling';
import { nodeListToArray } from '../../helpers/nodeListToArray';
import { isEscapeKey } from '../../modules/escape-key-detection';
declare const dataLayer;

export class SiteSearch extends BasicButtonToggling {
    private searchTriggers: Array<HTMLElement> = nodeListToArray(document.querySelectorAll("[data-search-trigger]"));
    private searchActivateButtons: Array<HTMLElement> = nodeListToArray(document.querySelectorAll("[data-search-activate]"));
    private searchCloseButton: HTMLElement = document.querySelector('[data-search-close]');
    private searchBackButton: HTMLElement = document.querySelector('[data-search-back]');
    private openSearchPanel: HTMLElement = CONSTANTS.GLOBAL_SEARCH_PANEL;
    constructor() {
        super();
        this.activateSearchTriggers();
        this.activateSearchFields();
    }
    handleEscape(e: KeyboardEvent) {
        if ((isEscapeKey(e)) && this.openSearchPanel) {
            this.closeSearch();
        }
    }
    focusToInput() {
        setTimeout(() => {
            const searchInput = (this.openSearchPanel.querySelector('[data-search-input]') as HTMLElement);
            searchInput.focus();
            searchInput.addEventListener("keyup", (e) => {
                e.preventDefault();
                if (e.keyCode == 13) {
                    var searchFieldValue = (searchInput as HTMLInputElement).value;
                    if (searchFieldValue !== "") {
                        var baseUrl = (<any>document.getElementById("redirectUrl")).value;
                        var redirectUrl: any = `${baseUrl}?q=${searchFieldValue}`;
                        (window as any).location.href = redirectUrl;
                    }
                }
            });
        }, 10)
    }
    toggleSearch() {
        if (!CONSTANTS.GLOBAL_BODY.hasAttribute(CONSTANTS.DATA_SEARCH_OPEN_ATTR)) {
            this.openSearch();
        }
        else {
            this.closeSearch();
        }
    }
    activateSearchTriggers() {
        if (this.searchTriggers.length > 0 && this.searchCloseButton) {
            this.searchTriggers.forEach(trigger => {
                trigger.addEventListener("click", this.handleClick, false);
                trigger.addEventListener("click", this.toggleSearch.bind(this), false);
            });

            this.searchCloseButton.addEventListener("click", this.closeSearch.bind(this), false);
            this.searchBackButton.addEventListener("click", this.searchGoBack.bind(this), false);
        }
    }
    activateSearchFields() {
        if (this.searchActivateButtons.length > 0) {
            this.searchActivateButtons.forEach(searchActivateButton => {
                searchActivateButton.addEventListener('click', () => {
                    var searchFieldValue: string = (document.getElementById(searchActivateButton.dataset.searchActivate) as HTMLInputElement).value;
                    if (searchFieldValue !== "") {
                        var baseUrl = (<any>document.getElementById("redirectUrl")).value;
                        var redirectUrl: any = `${baseUrl}?q=${searchFieldValue}`;
                        (window as any).location.href = redirectUrl;
                    }
                });
            });
        }

    }
    searchGoBack() {
        this.closeSearch();
        this.removeInert(CONSTANTS.GLOBAL_NAV_PANEL);
        this.addInert(CONSTANTS.GLOBAL_WRAPPER);
        this.addInert(CONSTANTS.GLOBAL_SKIPLINK);
        CONSTANTS.GLOBAL_BODY.classList.add(CONSTANTS.NAV_OPEN_CLASS);
        this.updateAriaExpanded(CONSTANTS.GLOBAL_NAV_TRIGGER, "true");
    }
    openSearch() {
        if (CONSTANTS.GLOBAL_BODY.hasAttribute(CONSTANTS.DATA_NAV_OPEN_ATTR) && (window as any).innerWidth >= CONSTANTS.GLOBAL_TABLET_BREAKPOINT) {
            NAV_CONSTANTS.MAIN_NAV.closeNav();
        }
        this.focusToInput();
        document.addEventListener('keyup', this.handleEscape.bind(this), false);
        CONSTANTS.GLOBAL_BODY.classList.add(CONSTANTS.NAV_OPEN_CLASS);
        this.addInert(CONSTANTS.GLOBAL_WRAPPER);
        this.addInert(CONSTANTS.GLOBAL_SKIPLINK);
        this.addInert(CONSTANTS.GLOBAL_NAV_PANEL);
        CONSTANTS.GLOBAL_BODY.setAttribute(CONSTANTS.DATA_SEARCH_OPEN_ATTR, 'true');
        this.openSearchGTM();
    }
    closeSearch() {
        CONSTANTS.GLOBAL_BODY.classList.remove(CONSTANTS.NAV_OPEN_CLASS);
        this.addInert(this.openSearchPanel);
        this.searchTriggers.forEach((trigger) => {
            this.updateAriaExpanded(trigger, "false");
        });
        this.removeInert(CONSTANTS.GLOBAL_WRAPPER);
        this.removeInert(CONSTANTS.GLOBAL_SKIPLINK);
        CONSTANTS.GLOBAL_NAV_TRIGGER.setAttribute('data-search-open', 'false');
        CONSTANTS.GLOBAL_BODY.removeAttribute(CONSTANTS.DATA_SEARCH_OPEN_ATTR);
    }

    openSearchGTM() {
        (window as any).dataLayer = (window as any).dataLayer || [];
        dataLayer.push({
            'event': 'Site Navigation',
            'event_category': 'Site Navigation',
            'event_action': 'Open Internal Search',
            'event_label': ''
        });
    }
}