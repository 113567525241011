import * as CONSTANTS from '../modules/constants';
export class AnimateBlocks {
    public scrolledToAttribute: string;
    public blocks: Array<HTMLElement>;
    constructor(blocks: Array<HTMLElement>) {
        this.scrolledToAttribute = CONSTANTS.SCROLL_TO_ATTR;
        this.blocks = blocks;
        if (this.blocks.length > 0)
            this.observeBlocks();
    }

    observeBlocks() {
        const blockOptions = {
            root: null,
            rootMargin: '0px',
            threshold: 0
        }

        this.blocks.forEach(block => {
            const observer = new IntersectionObserver(
                (entries) => {
                    entries.forEach((entry) => {
                        if ((entry as any).isIntersecting) {
                            if (!entry.target.hasAttribute(this.scrolledToAttribute))
                                block.setAttribute(this.scrolledToAttribute,"");
                        }
                    });
                }, blockOptions);
            observer.observe(block);
        });
    };
}