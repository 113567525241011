import * as CONSTANTS from '../../modules/constants';

export function scrollToTop() {
    let scrollToTopBtn = document.getElementById('stickyScrollTop');
    let mainElement = document.getElementById('mainContent');
    let showBackToTopClass = 'show-back-to-top';

    if (scrollToTopBtn != null) {
        scrollToTopBtn.addEventListener('click',
            function() {
                window.scroll({
                    top: 0,
                    behavior: 'smooth'
                });
            });


        window.addEventListener('scroll',
            () => {
                window.requestAnimationFrame(() => {
                    let documentScroll = Math.round(window.pageYOffset) || document.documentElement.scrollTop;
                    if (documentScroll > 750) {
                        CONSTANTS.GLOBAL_BODY.classList.add(showBackToTopClass);

                    } else {
                        CONSTANTS.GLOBAL_BODY.classList.remove(showBackToTopClass);
                    }

                    if (window.innerHeight > mainElement.getBoundingClientRect().bottom) {
                        if (scrollToTopBtn != null) {
                            scrollToTopBtn.classList.remove('fixed');
                        }
                    } else {
                        if (scrollToTopBtn != null) {
                            scrollToTopBtn.classList.add('fixed');
                        }
                    }
                });
            });
    }
}