import { Modal } from './modal';
import * as YouTubeIframeLoader from 'youtube-iframe';

export class DirectLinkVideoModal {
    private videoModal: HTMLElement;
    private videoModalIframe: HTMLIFrameElement;
    private urlParams: URLSearchParams;
    private videoParam: string;
    private modalTrigger: HTMLButtonElement;

    constructor() {
        this.videoModal = (document.querySelector('[data-direct-link-video-modal]') as HTMLElement);
        this.videoModalIframe = (document.querySelector('[data-direct-link-video-modal-iframe]') as HTMLIFrameElement);
        this.urlParams = new URLSearchParams(window.location.search);
        this.videoParam = this.urlParams.get('videoId');
        this.modalTrigger = (document.querySelector('[data-direct-link-video-modal-btn]') as HTMLButtonElement);
        this.init();
    }

    youtubeInit() {
        this.populateModalInfo();
        this.activateModal();

    }
    onPlayerReady(player) {
        player.playVideo();
    }

    init() {
        if (this.videoParam) {
            this.youtubeInit();
        }
    }
    activateModal() {
        this.modalTrigger.click();
    }
    populateModalInfo() {
        this.videoModalIframe.setAttribute("data-src", `https://www.youtube.com/embed/${this.videoParam}?autoplay=1&mute=1&enablejsapi=1`)
        new Modal(this.videoModal, true, null);
    }
}