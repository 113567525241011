import * as CONSTANTS from '../constants';
import * as NAV_CONSTANTS from '../nav-constants';
import { isEscapeKey } from '../../modules/escape-key-detection';
import { BasicButtonToggling } from '../../modules/basic-button-toggling';
import { nodeListToArray } from '../../helpers/nodeListToArray';
import { enableTabbableChildren } from '../../helpers/enableTabbableChildren';
import { disableTabbableChildren } from '../../helpers/disableTabbableChildren';
import { debounce } from 'lodash';

declare const dataLayer;

export class MainNavigation extends BasicButtonToggling {
    private navigationPanelToToggle: HTMLElement = CONSTANTS.GLOBAL_NAV_PANEL;
    private mnavTrigger: HTMLElement = CONSTANTS.GLOBAL_NAV_TRIGGER;
    private menuExpandedStatus: boolean = false;
    private subMenuButtons = nodeListToArray(this.navigationPanelToToggle.querySelectorAll('[data-subnav-trigger]'));
    private navImage: HTMLElement = document.querySelector('[data-nav-image]') as HTMLElement;
    private header = document.getElementById('header');
    private main = document.getElementById('mainContent');

    constructor() {
        super();
        if (this.mnavTrigger) {
            this.mnavTrigger.addEventListener("click", this.handleMenuClick, false);
            this.subMenuButtons.forEach(button => {
                button.addEventListener("click", this.handleSubMenuClick, false);
            });
        }
        setTimeout(() => { 
            this.setNavClearance();
        }, 0)

        window.addEventListener(
            'resize',
            debounce(() => {
                this.setNavClearance();
            }, 50)
        );
    }

    setNavClearance() {
        document.documentElement.style.setProperty('--nav-clearance', `${this.header.offsetHeight.toString()}px`);
    }

    hideElement(element: HTMLElement) {
        element.setAttribute('hidden', '');
        this.ariaHide(element);
    }
    showElement(element: HTMLElement) {
        element.removeAttribute('hidden');
        this.ariaShow(element);
    }
    ariaShow(element: HTMLElement) {
        element.setAttribute('aria-hidden', 'false');
    }
    ariaHide(element: HTMLElement) {
        element.setAttribute('aria-hidden', 'true');
    }
    clickIsOutsideHeader(element: HTMLElement) {
        return (element.closest('#header') === null) ? true : false;
    }
    updateTextToBeClosed(trigger: HTMLElement) {
        if ((trigger.querySelector('[data-menu-close]') as HTMLElement) && (trigger.querySelector('[data-menu-open]') as HTMLElement)) {
            this.hideElement((trigger.querySelector('[data-menu-close]') as HTMLElement));
            this.showElement((trigger.querySelector('[data-menu-open]') as HTMLElement));
        }
    }
    updateTextToBeOpen(trigger: HTMLElement) {
        if ((trigger.querySelector('[data-menu-close]') as HTMLElement) && (trigger.querySelector('[data-menu-open]') as HTMLElement)) {
            this.showElement((trigger.querySelector('[data-menu-close]') as HTMLElement));
            this.hideElement((trigger.querySelector('[data-menu-open]') as HTMLElement));
        }
    }
    updateMenuExpandedStatus(newStatus: boolean) {
        this.menuExpandedStatus = newStatus;
    }
    handleEscape(e: KeyboardEvent) {
        if (isEscapeKey(e) && this.menuExpandedStatus) {
            this.toggleNav();
        }
    }
    openNav() {
        if (CONSTANTS.GLOBAL_BODY.hasAttribute(CONSTANTS.DATA_SEARCH_OPEN_ATTR) && (window as any).innerWidth >= CONSTANTS.GLOBAL_TABLET_BREAKPOINT) {
            NAV_CONSTANTS.SITE_SEARCH.closeSearch();
        }
        this.updateMenuExpandedStatus(true);
        this.updateAriaExpanded(this.mnavTrigger, (this.menuExpandedStatus).toString());
        this.updateTextToBeOpen(this.mnavTrigger);
        this.addInert(CONSTANTS.GLOBAL_WRAPPER);
        this.addInert(CONSTANTS.GLOBAL_SKIPLINK);
        this.removeInert(this.navigationPanelToToggle);
        CONSTANTS.GLOBAL_BODY.classList.add(CONSTANTS.NAV_OPEN_CLASS);
        document.addEventListener('keyup', this.handleEscape.bind(this), false);
        CONSTANTS.GLOBAL_BODY.setAttribute('data-nav-open', 'true');
        this.toggleNavGTM("Open Hamburger");
    }
    closeNav() {
        if (CONSTANTS.GLOBAL_BODY.hasAttribute(CONSTANTS.DATA_SEARCH_OPEN_ATTR) && (window as any).innerWidth < CONSTANTS.GLOBAL_TABLET_BREAKPOINT) {
            NAV_CONSTANTS.SITE_SEARCH.closeSearch();
        }
        this.updateMenuExpandedStatus(false);
        this.updateAriaExpanded(this.mnavTrigger, (this.menuExpandedStatus).toString());
        this.updateTextToBeClosed(this.mnavTrigger);
        this.addInert(this.navigationPanelToToggle);
        CONSTANTS.GLOBAL_BODY.classList.remove(CONSTANTS.NAV_OPEN_CLASS);
        this.removeInert(CONSTANTS.GLOBAL_WRAPPER);
        this.removeInert(CONSTANTS.GLOBAL_SKIPLINK);
        document.removeEventListener('keyup', this.handleEscape.bind(this), false);
        CONSTANTS.GLOBAL_BODY.removeAttribute('data-nav-open');
        this.toggleNavGTM("Close Hamburger");
    }
    handleMenuClick = (e: Event) => {
        e.stopPropagation();
        this.toggleNav();
    }
    closeOpenSubMenus() {
        this.subMenuButtons.forEach(subMenuButton => {
            const subMenuToOpen: HTMLElement = document.getElementById(subMenuButton.getAttribute('aria-controls'));
            super.addInert(subMenuToOpen);
            super.updateAriaExpanded(subMenuButton, "false");
        });
    }
    handleSubMenuClick = (e: Event) => {
        e.stopPropagation();
        const subMenuTrigger: HTMLElement = (super.testAriaControls((e.target as HTMLElement)) as HTMLElement);
        const subMenuToOpen: HTMLElement = document.getElementById(subMenuTrigger.getAttribute('aria-controls'));
        const subMenuExpandedStatus = (subMenuTrigger.getAttribute('aria-expanded') === 'true') ? true : false;
        this.closeOpenSubMenus();
        if (!subMenuExpandedStatus) {
            super.removeInert(subMenuToOpen);
            super.updateAriaExpanded(subMenuTrigger, "true");
            enableTabbableChildren(subMenuToOpen);
            this.toggleSubNavGTM("Open Site Section", subMenuTrigger.innerText)
        }
        else {
            super.addInert(subMenuToOpen);
            super.updateAriaExpanded(subMenuTrigger, "false");
            this.navImage.setAttribute('src', this.navImage.getAttribute('data-nav-image-src'));
            disableTabbableChildren(subMenuToOpen);
            this.toggleSubNavGTM("Close Site Section", subMenuTrigger.innerText)
        }
        this.updateNavImage(subMenuExpandedStatus, subMenuTrigger);
    }
    updateNavImage(expandedStatus: boolean, trigger: HTMLElement) {
        (!expandedStatus) ? this.navImage.setAttribute('src', trigger.getAttribute('data-nav-image-src')) : this.navImage.setAttribute('src', this.navImage.getAttribute('data-nav-image-src'));
    }
    toggleNav() {
        (!this.menuExpandedStatus) ? this.openNav() : this.closeNav();
    }

    toggleNavGTM(navState: string) {
        (window as any).dataLayer = (window as any).dataLayer || [];
        dataLayer.push({
            'event': 'Site Navigation',
            'event_category': 'Site Navigation',
            'event_action': navState,
            'event_label': ''
        });
    }

    toggleSubNavGTM(subNavState, itemName) {
        (window as any).dataLayer = (window as any).dataLayer || [];
        dataLayer.push({
            'event': 'Site Navigation',
            'event_category': 'Site Navigation',
            'event_action': subNavState,
            'event_label': itemName
        });
    }
}