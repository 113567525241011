export function svgInject(svgURL: string) {
    let request = new XMLHttpRequest();
    request.open('GET', svgURL, true);
    request.onload = function () {
        if (request.status >= 200 && request.status < 400) {
            var data = request.responseText;
            // Create node with response data and insert into DOM
            var div = document.createElement('div');
            div.innerHTML = data;
            document.body.insertBefore(div, document.body.childNodes[0]);
        }
    };
    request.onerror = function () {
        //console.log("Couldn't load SVG sprite - connection error");
    };
    request.send();
}