export function CSSToolTip() {
    var cssToolTips = document.querySelectorAll('.tool-tip-css');

    if (cssToolTips.length > 0) {
        for (var i = 0; i < cssToolTips.length; i++) {
            let toolTipText = cssToolTips[i].innerHTML;
            let toolTipSvg = '<svg aria-hidden="true"><use xlink:href="#icon-question-mark"></use></svg>'
            let toolTipNew = toolTipSvg + '<span class="tool-tip-css-text"><span class="tool-tip-css-text-inner">' + toolTipText + '</span></span>';
            cssToolTips[i].setAttribute('tabIndex', '0');
            cssToolTips[i].innerHTML = toolTipNew;
        }
    }
}