//The auto column block should have been a cta container block and a separate container block should have been created for media items.
//Since that did not happen, we use this script below to detect if an auto column container contains any media items. If it does, we apply a css class to style as necessary.
//Otherwise, the media items will not appear correctly.
import { nodeListToArray } from '../helpers/nodeListToArray';
import { imageOrientation } from '../modules/image-orientation';
import * as CONSTANTS from '../modules/constants';

export function autoColumnFixer() {
    let autoColumnBlocks = nodeListToArray(document.querySelectorAll('[data-auto-columns]'));
    autoColumnBlocks.forEach(autoColumnBlock => {
        let mediaItems;
        mediaItems = nodeListToArray(autoColumnBlock.querySelectorAll('.adage-columns-auto-container > [data-media-item]'));
        //If there are no headers, images, or media items. Give the element a class with reduced padding. This was necessary and admittedly not an ideal solution: https://adagetechnologies.atlassian.net/browse/LYRCS-229
        if (CONSTANTS.GLOBAL_HTML.classList.contains(CONSTANTS.EPI_EDIT_MODE_CLASS)) {
            mediaItems = nodeListToArray(autoColumnBlock.querySelectorAll('.adage-columns-auto-container [data-media-item]'));
        }
        if (mediaItems.length > 0) {
            autoColumnBlock.classList.add('adage-auto-columns-media');
        }
        mediaItems.forEach((mediaItem) => {
            if (mediaItem.dataset.mediaItem = "image") { 
                imageOrientation(mediaItem, 'adage-image-block-portrait');
            }
        });
    });

}