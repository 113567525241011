import * as CONSTANTS from '../../modules/constants';
import * as NAV_CONSTANTS from '../../modules/nav-constants';
import { FullBleedImage } from '../../modules/fullBleedImagesIE';
import { Accordion } from '../../modules/accordion';
import { nodeListToArray } from '../../helpers/nodeListToArray';
import { pageNav } from '../../modules/navigation/page-navigation';
import { scrollToTop } from '../../modules/navigation/scroll-top'; 
import { autoColumnFixer } from '../../blocks/autocolumnFixer';
import { initPhoneFormatter } from '../../forms/phoneFormatter';
import { Gallery } from '../../modules/gallery';
import { svgInject } from '../../modules/svg-loader';
import { Modal } from '../../modules/modal';
import { CSSToolTip } from '../../modules/css-tooltip';
import { AnimateBlocks } from '../../modules/animate-blocks';
import { ScrollPrompt } from '../../modules/scroll-prompt';
import { ButtonRole } from '../../modules/button-roles';
import { ArtistListing } from '../../blocks/artistListing';
import { Alert } from '../../modules/alert';
import { DirectLinkVideoModal } from '../../modules/directLinkVideoModal';
import { closestPolyFill } from '../../helpers/closestPolyfill';
import { getDevice } from '../../helpers/getDevice';
import { StickyObserver } from '../../modules/stickyElement';
import { toggleContainer } from '../../modules/toggleContainer';

(() => {
    const sponsorBlockAnimateAreas = nodeListToArray(document.querySelectorAll('.adage-sponsor-grid [data-block-transition-amount="6rem"]'));//Prevents animatin wysiwyg block that might be insde of the sponsor block.
    if (sponsorBlockAnimateAreas.length > 0) {
        sponsorBlockAnimateAreas.forEach(block => {
            block.removeAttribute('data-block-transition-amount');
        });
    }
    new AnimateBlocks(nodeListToArray(document.querySelectorAll('[data-block-transition-amount="6rem"]')));
    //Insert Helper Text for links that open in a new tab
    const externalLinks = nodeListToArray(document.querySelectorAll('a[target=_blank]'));
    externalLinks.forEach(link => {
        link.setAttribute('aria-describedby', 'a11y-message--new-window');
    });

    //Update the list below if sticky nav is introduced to other pages for tracking purposes 
    //Page Sticky Navigation (pages that use it: Play Details Page)
    let subNavLinks: any = nodeListToArray(document.querySelectorAll(".subnav-list-link-js"));
    if (subNavLinks.length > 0) {
        pageNav(subNavLinks);
    }
    scrollToTop();

    autoColumnFixer();

    // Accordion Init
    let accordions = new Accordion({
        oneOpenAtATime: true
    });

    // Alert Init
    let alerts = new Alert();

    // Phone Formatter Init
    initPhoneFormatter();

    // SVG Sprite Inject
    svgInject('/Static/images/svg-sprite.svg');

    // Modals Init
    let modals = nodeListToArray(document.querySelectorAll('[data-modal]:not([data-direct-link-video-modal])'));
    const allModals = {};
    window["allModalRefs"] = allModals;
    modals.forEach(modal => {
        const modalRef = new Modal(modal, true);
        allModals[(modal as HTMLElement).id] = modalRef;
    });

    // Fix images in IE that must be full-bleed
    let fullBleedImages = nodeListToArray(document.querySelectorAll('[data-full-bleed-image] img'));
    fullBleedImages.forEach(imageEl => {
        new FullBleedImage(imageEl);
    }); 

    //Activate Galleries/Carousels
    const contentGalleries = (CONSTANTS.IS_HOMEPAGE) ? nodeListToArray(document.querySelectorAll('[data-content-gallery]:not([data-content-gallery=noGalleryOnHome])')) : nodeListToArray(document.querySelectorAll('[data-content-gallery]'));
    const contentGalleryCssClass = 'adage-gallery';
    function activateGallery(gallery,index){
        gallery.classList.add(`${contentGalleryCssClass}-${index}`);
        const prevButton = document.getElementById(`contentGalleryPrev_${gallery.getAttribute("data-unique-id")}`);
        const nextButton = document.getElementById(`contentGalleryNext_${gallery.getAttribute("data-unique-id")}`);
        const slidesToShow = (gallery.getAttribute('data-slides-to-show') !== 'auto') ? parseInt(gallery.getAttribute('data-slides-to-show')) : gallery.getAttribute('data-slides-to-show');
        new Gallery({
            containerClass: `${contentGalleryCssClass}-${index}`,
            autoHeight: (gallery.getAttribute('data-gallery-autoheight') === 'false') ? false : true,
            slidesPerView: slidesToShow,
            loop: false,
            navigation: {
                nextEl: nextButton,
                prevEl: prevButton,
            },
        })
    }
    contentGalleries.forEach((gallery, index) => {
        activateGallery(gallery, index);
    });

    const ShowListingBlocks = nodeListToArray(document.querySelectorAll('[data-listing-gallery]'));
    let showListIndex = 0;
    ShowListingBlocks.forEach(gallery => {

        const prevButton = document.getElementById(`contentGalleryPrev_${gallery.getAttribute("data-unique-id")}`);
        const nextButton = document.getElementById(`contentGalleryNext_${gallery.getAttribute("data-unique-id")}`);
        gallery.classList.add(`content-list-gallery-${showListIndex}`);
        new Gallery({
            containerClass: `content-list-gallery-${showListIndex}`,
            slidesPerView: 3,
            spaceBetween: 38,
            breakpoints: {
                640: {
                    slidesPerView: 1,
                }
            },
            loop: false,
            autoHeight: false,
            navigation: {
                nextEl: nextButton,
                prevEl: prevButton,
            },
        })
        showListIndex++;
    });

    //Activate Button Roles
    new ButtonRole();

    //Artist Listing
    new ArtistListing();

    // Modal Direct Linking
    new DirectLinkVideoModal();

    document.addEventListener("DOMContentLoaded", () => {
        if (!CONSTANTS.GLOBAL_HTML.classList.contains(CONSTANTS.EPI_EDIT_MODE_CLASS)) {
            CSSToolTip();
            NAV_CONSTANTS.MAIN_NAV;
            NAV_CONSTANTS.SITE_SEARCH;
            new ScrollPrompt();
        }
    });

    if (getDevice()) {
        document.body.classList.add('isDevice');
    }

    // Sticky Observers
    const stickyContainerEls = [].slice.call(document.querySelectorAll('[data-sticky-element]')) as HTMLElement[];
    stickyContainerEls.forEach(stickyEl => {
        new StickyObserver(stickyEl);
    })

    // Toggle Containers
    let containers = nodeListToArray(document.querySelectorAll('[data-toggle-container]'));
    containers.forEach((e) => {
        new toggleContainer(e);
    })
})();