import { nodeListToArray } from '../helpers/nodeListToArray';
import * as CONSTANTS from '../modules/constants';

export class ArtistListing {
    private artistList = document.querySelector("[data-artist-list]");
    private artistLoadMoreBtn = document.querySelector('[data-artist-loadmore]');
    private artistAnnouncementPanel = document.querySelector('[data-artist-announcement]');
    private hiddenArtists = nodeListToArray(document.querySelectorAll('[data-artist-item-hide]'));
    constructor() {
        if (this.artistLoadMoreBtn) {
            this.activateLoadMoreBtn();
        }
    }
    activateLoadMoreBtn() {
        this.artistLoadMoreBtn.addEventListener('click', () => {
            this.showHiddenArtists();
        });
    }
    hideLoadMore() {
        this.artistLoadMoreBtn.classList.add('artist-list-loadmore-hide');
    }
    showHiddenArtists() {
        if (this.hiddenArtists.length > 0) {
            this.updateAriaLive(this.hiddenArtists.length);
            this.hiddenArtists.forEach(hiddenArtist => {
                hiddenArtist.removeAttribute("data-artist-item-hide");
            });
            this.hideLoadMore();
            this.focusToArtists();
        }
    }
    updateAriaLive(amountOfArtistsShown: number) {
        if (this.artistAnnouncementPanel) {
            this.artistAnnouncementPanel.innerHTML = `${amountOfArtistsShown} more ${(amountOfArtistsShown > 1) ? "artists" : "artist"} revealed in the artist list.`
        }
    }
    focusToArtists() {
        (this.artistList as HTMLElement).focus();
    }
}   