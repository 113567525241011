import * as CONSTANTS from '../../modules/constants';

import { pixelsToRems } from '../../helpers/pixelsToRems';

export function pageNav(links) {
    let subNav = CONSTANTS.GLOBAL_SUBNAV;
    let stickySubNavClass = 'subnav-stuck';

    function stickyElement(elementToObserv) {
        let navClearance = "-" + getComputedStyle(document.documentElement)
            .getPropertyValue('--nav-clearance');
        const options = {
            root: null,
            rootMargin: navClearance,
            threshold: 0
        }
        let observer = new IntersectionObserver(
            (entries) => {
                entries.forEach((entry) => {
                    (!(entry as any).isIntersecting) ? CONSTANTS.GLOBAL_BODY.classList.add(stickySubNavClass) : CONSTANTS.GLOBAL_BODY.classList.remove(stickySubNavClass);
                });
            }, options);
        observer.observe(elementToObserv);
    }
    window.addEventListener('scroll', () => {
        stickyElement(document.querySelector('[data-subnav-watcher]'));
    });
}