import * as CONSTANTS from '../modules/constants';
import A11yDialog = require('a11y-dialog');
import { nodeListToArray } from '../helpers/nodeListToArray';
import * as YouTubeIframeLoader from 'youtube-iframe';
import { Player } from "@vimeo/player";

export class Modal {
    modal: any;
    modalElement: HTMLElement;
    video?: any;
    muteVideo: boolean;
    constructor(modalElement: HTMLElement, muteVideo?: boolean, container?: HTMLElement) {
        this.modal = new A11yDialog(modalElement as any);
        this.modalElement = modalElement;
        this.muteVideo = muteVideo;
        this.modal.on('show', () => {
            CONSTANTS.GLOBAL_BODY.classList.add('adage-modal-open');
            (CONSTANTS.GLOBAL_BODY as HTMLElement).appendChild(this.modalElement);
            const title = (this.modalElement.querySelector(`[data-a11y-dialog-title]`) as HTMLElement);
            if (title)
                title.focus();
            this.initVideo();
        });
        this.modal.on('hide', () => {
            CONSTANTS.GLOBAL_BODY.classList.remove('adage-modal-open');
            this.pauseVideo();
        });
    }

    initVideo() {
        let video = this.modalElement.querySelector('[data-video-type]');
        if (video) {
            let videoType = video.getAttribute('data-video-type');
            if (videoType == 'vimeo') {
                this.vimeoInit();
            }
            else if (videoType == 'youtube') {

                this.youtubeInit();
            }
            else {
                this.nativeVideoInit();
            }
        }
    }

    vimeoInit() {
        let vimeoIframe = this.modalElement.querySelector('iframe');
        if (!vimeoIframe.src) {
            vimeoIframe.src = vimeoIframe.getAttribute('data-src');
            let vimeoPlayer = new Player(vimeoIframe, {});
            this.video = vimeoPlayer;
        }
    }

    youtubeInit() {
        let youtubeIframe = this.modalElement.querySelector('iframe');
        if (!youtubeIframe.src) {
            let uniqueID = this.modalElement.id;
            YouTubeIframeLoader.load(YT => {
                let playerID = `youtube-player-${uniqueID}`;
                youtubeIframe.id = playerID;
                youtubeIframe.src = youtubeIframe.getAttribute('data-src');
                //Init Video and play
                let youtubePlayer = new YT.Player(playerID, {
                    events: {
                        'onReady': () => {
                            if (this.muteVideo)
                                youtubePlayer.mute();
                            youtubePlayer.playVideo();
                        }
                    }
                });
                this.video = youtubePlayer;
            });
        }

    }

    nativeVideoInit() {
        let videoElement = this.modalElement.querySelector('video');
        if (videoElement) {
            let videoSource = videoElement.querySelector('source');
            if (!videoSource.src) {
                videoSource.src = videoSource.getAttribute('data-src');
                videoElement.load();
            }
            this.video = videoElement;
        }
    }

    pauseVideo() {
        let videoContainer = this.modalElement.querySelector('[data-video-type]');
        if (videoContainer) {
            if (videoContainer.getAttribute('data-video-type') == 'vimeo') {
                this.video.pause();
            }
            else if (videoContainer.getAttribute('data-video-type') == 'youtube') {
                this.video.pauseVideo();
            }
            else {
                if (!this.video.paused) {
                    this.video.pause();
                }
            }
        }
    }
}